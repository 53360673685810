import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const title = 'The Ould Irish Jig';

const article = `
# The Ould Irish Jig
### _James McKowen (1814 - 1889)_

> My blessing be on you, old Erin,
> My own land of frolic and fun;
> For all sorts of mirth and diversion,
> Your like is not under the sun.
> Bohemia may boast of her polka,
> And Spain of her waltzes talk big;
> Sure, they are all nothing but limping,
> Compared to our ould Irish jig.
> Then a fig for your new-fashioned waltzes,
> Imported from Spain and from France;
> And a fig for the thing called the polka -
> Our own Irish jig we will dance.
>
> I've heard how our jig came in fashion -
> And believe that the story is true -
> By Adam and Eve 'twas invented,
> The reason was, partners were few.
> And, though they could both dance the polka,
> Eve thought it was not over-chaste;
> She preferred our ould jig to be dancing -
> And, faith, I approve of her taste.
>
> Chorus.
>
> The light hearted daughters of Erin,
> Like the wild mountain deer they can bound,
> Their feet never touch the green island,
> But music is struck from the ground.
> And oft in the glens and green meadows,
> The ould jig they dance with such grace,
> That even the daises they tread on,
> Look up with delight on their face.
>
> Chorus.
>
> An ould Irish jig, too, was danced by
> The kings and the great men of yore,
> King O'Toole could himself neatly foot it
> To a tune they called "Rory O'More".
> And oft in the great hall of Tara,
> Our famous King Brian Boru,
> Danced an ould jig with his nobles ,
> And played his own harp to them too.
>
> Chorus.
>
> And sure, when Herodias' daughter
> Was dancing in King Herod's sight,
> His heart that for years had been frozen,
> Was thawed with pure love and delight;
> And more than a hundred times over,
> I've heard Father Flanagan tell,
> 'Twas our own Irish jig that she footed,
> That pleased that ould villain so well.
>
> Chorus.
`;

const OuldIrishJig = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default OuldIrishJig;
