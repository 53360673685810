import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const title = 'The Ould Irish Jig';

const article = `
# Public Dance Halls Act 1935

The Public Dance Halls Act 1935 was enacted by the Irish Government in 1935. Almost from the date of its introduction it has been believed to have been a significant factor in the decline in the practice of traditional music in rural Ireland.

Below, you will find some background information as well as historical information regarding the Act.

You can download a full copy of the Public Dance Halls Act 1935

`;

const DanceHallsAct = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default DanceHallsAct;
