import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';
import lanigans1 from './lanigans1.gif';

const title = 'Lanigan\'s Ball';

const article = `
# ${title}
### _George Gavan (1860)_

![Lanigan's Ball Music](lanigans1.gif "Lanigan's Ball Music")

> In the town of Athy one Jeremy Lanigan
> Battered away 'til he hadn't a pound.
> His father he died and made him a man again
> Left him a farm and ten acres of ground.
> He gave a grand party to friends and relations
> Who didn't forget him when it comes to the will,
> And if you'll but listen I'll make your eyes glisten
> Of the rows and the ructions of Lanigan's Ball.
> Chorus:
> Six long months I spent in Dublin,
> Six long months doing nothing at all.
> Six long months I spent in Dublin,
> Learning to dance for Lanigan's ball.

> Myself to be sure got free invitation,
> For all the nice girls and boys I might ask,
> And just in a minute both friends and relations
> Were dancing 'round merry as bees 'round a cask.
> Judy O'Daly, that nice little milliner,
> She tipped me a wink for to give her a call,
> And I soon arrived with Peggy McGilligan
> Just in time for Lanigan's Ball.
> Chorus:

> There were lashings of punch and wine for the ladies,
> Potatoes and cakes; there was bacon and tea,
> There were the Nolans, Dolans, O'Gradys
> Courting the girls and dancing away.
> Songs they went 'round as plenty as water,
> "The harp that once sounded in Tara's old hall,"
> "Sweet Nelly Gray" and "The Rat Catcher's Daughter,"
> All singing together at Lanigan's Ball.
> Chorus:

> They were doing all kinds of nonsensical polkas
> All 'round the room in a whirligig.
> Julia and I, we banished their nonsense
> And tipped them the twist of a reel and a jig.
> &'Och mavrone, how the girls got all mad at me
> Danced 'til you'd think the ceiling would fall.
> For I spent three weeks at Brooks' Academy
> Learning new steps for Lanigan's Ball.

> 2nd Chorus:
> She stepped out and I stepped in again,
> I stepped out and she stepped in again,
> She stepped out and I stepped in again,
> Learning new steps for Lanigan's Ball.

> Boys were all merry and the girls they were hearty
> And danced all around in couples and groups,
> 'Til an accident happened, young Terrance McCarthy
> Put his right leg through miss Finnerty's hoops.
> Poor creature fainted and cried, "Meelia murther",

> Called for her brothers and gathered them all.
> Carmody swore that he'd go no further
> 'Til he had satisfaction at Lanigan's Ball.
> 2nd Chorus:

> In the midst of the row miss Kerrigan fainted,
> Her cheeks at the same time as red as a rose.
> Some of the lads declared she was painted,
> She took a small drop too much, I suppose.
> Her sweetheart, Ned Morgan, so powerful and able,
> When he saw his fair colleen stretched out by the wall,
> Tore the left leg from under the table
> And smashed all the Chaneys at Lanigan's Ball.
> 2nd Chorus:

> Boys, oh boys, 'twas then there were ructions.
> Myself got a lick from big Phelim McHugh.
> I soon replied to his introduction
> And kicked up a terrible hullabaloo.
> Old Casey, the piper, was near being strangled.
> They squeezed up his pipes, bellows, chanters and all.
> The girls, in their ribbons, they got all entangled
> And that put an end to Lanigan's Ball.
> 2nd Chorus:
`;

const LanigansBall = () => {
	const renderers = {
		image: ({ alt, src, title }) => {
			const img = {
				'lanigans1.gif': lanigans1
			}[src];

			return (
				<img src={img} alt={alt} title={title} />
			);
		},
		blockquote: ({ children, node }) => {
			const lines = children[0].props.children[0].props.value.split('\n')
			return (
				<blockquote>
					{lines.map(a => <>{a}<br /></>)}
				</blockquote>
			);
		}
	}

	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown renderers={renderers}>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default LanigansBall;
