import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';
import dance1 from './dlfmhluy1.jpg';
import dance2 from './dlfmhluy2.jpg';

const article = `
# Dance light for my heart lies under your feet
### _John Francis Waller (1809-1894)_

This nineteenth century song is taken from Samuel Lover's The Lyrics of Ireland (Houlston & Wright, London 1858). The image is by Dalziel and accompanied the poem in Lover's collection. The prescribed air is "Huish the cat from under the table".

![Dance Light Music](dlfmhluy1.jpg "Dance Light")

"Ah, sweet Kitty Neil, rise up from that wheel?,
Your neat little foot will be weary from spinning;
Come trip down with me to the sycamore tree,
Half the parish is there and the dance is beginning.
The sun has gone down, but the full harvest moon
Shines sweetly and cool on the dew-whitened valley;
While all the air rings with the soft loving things
Each little bird sings in the green shaded valley!"

With a blush and a smile, Kitty rose up the while,
Her eyes in the glass, as she bound her hair, glancing;
'Tis hard to refuse when a young lover sues,?
So she couldn't but choose to go off to the dancing.
And now on the green the glad groups are seen,
Each gay-hearted lad with the lass of his choosing;
And Pat, without fail, leads out sweet Kitty Neil,?
Somehow, when he asked, she ne'er thought of refusing.

Now Felix Magee puts his pipes to his knee,
And with flourish so free sets each couple in motion;
With a cheer and a bound the lads patter the ground,?
The maids move around just like swans on the ocean.
Cheeks bright as the rose, feet light as the doe's,
Now coyly retiring, now boldly advancing,?
Search the world all around, from the sky to the ground,
No such sight can be found as an Irish lass dancing!

Sweet Kate! who could view your bright eyes of deep blue,
Beaming humidly through their dark lashes so mildly,?
Your fair-turned arm, heaving breast, rounded form,?
Nor feel his heart warm and his pulses throb wildly?
Young Pat feels his heart, as he gazes, depart,
Subdued by the smart of such painful yet sweet love;
The sight leaves his eye, as he cries, with a sigh,

_"Dance light, for my heart it lies under your feet, love!"_

![Woman Dancing](dlfmhluy2.jpg "Woman Dancing")

`;

const DanceLight = () => {
	const renderers = {
		image: ({ alt, src, title }) => {
			let img;
			if (src == 'dlfmhluy1.jpg') {
				img = dance1;
			} else if (src == 'dlfmhluy2.jpg') {
				img = dance2;
			}
			return (
				<img src={img} alt={alt} title={title} />
			)
		},
	}
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Dance light for my heart lies under your feet
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						Dance light for my heart lies under your feet
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown renderers={renderers}>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default DanceLight;
