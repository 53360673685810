import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const article = `
# Evils of Dancing
### Statement of the Archbishops and Bishops of Ireland issued at their Meeting, held in Maynooth, on 6th October, 1925.

To be read, until further notice, at the principal Masses, in all Churches on the first Sunday of each Quarter of the Ecclesiastical Year
We have a word of entreaty, advice and instruction, to speak to our flocks on a very grave subject. There is danger of losing the name which the chivalrous honour of Irish boys and the Christian reserve of Irish maidens had won for Ireland. If our people part with the character that gave rise to that name, we lose with it much of our national strength, and still more of the high rank we have held in the Kingdom of Christ.

Purity is strength, and purity and faith go together. Both virtues are in danger these times, but purity is more directly assailed than faith. The danger comes from pictures and papers and drink. It comes more from the keeping of improper company than from any other cause; and there is no worse fomenter of this great evil than the dancing hall.

We know too well the fruits of these halls all over the country. It is nothing new, alas, to find Irish girls now and then brought to shame, and retiring to the refuge of institutions or the dens of great cities. But dancing halls, more especially, in the general uncontrol of recent years have deplorably aggravated the ruin of virtue due to ordinary human weakness. They have brought many a good, innocent girl into sin, shame and scandal, and set her unwary feet on the road that leads to perdition.

Given a few frivolous young people in a locality and a few careless parents, and the agents of the wicked one will come there to do the rest, once a dance is announced without proper control. They may lower or destroy the moral tone of the whole countryside.

Action has to be taken while the character of the people as a whole is still sound to stop the dangerous laxity that has been creeping into town and country.

Amusement is legitimate, though some of our people are overgiven to play. What, however, we condemn is sin and the dangerous occasions of sin. Wherever these exist, amusement is not legitimate. It does not deserve the name of amusement among Christians. It is the sport of the evil spirit for those who have no true self-respect.

The occasions of sin and sin itself are the attendants of night dances in particular. There may be and are exceptions, but they are comparatively few.

To say nothing of the special danger of drink, imported dances of an evil kind, the surroundings of the dancing hall, withdrawal from the hall for intervals, and the dark ways home have been the destruction of virtue in every part of Ireland.

The dancing of dubious dances on Sunday, more particularly by persons dazed with drink, amounts to woeful desecration of the Lord's Day wherever it takes place.

Against such abuses, duty to God and love of our people compel us to speak out. And what we have to say each for his own diocese, is that we altogether condemn the dangerous occasions, the snares, the unchristian practices to which we have referred.

Very earnestly do we trust that it may not be necessary for us to go further.

Our young people can have plenty of worthy dancing with proper supervision, and return home at a reasonable hour. Only in special circumstances under most careful control, are all-night dances permissible.

It is no small commendation of Irish dances that they cannot be danced for long hours. That, however, is not their chief merit, and, while it is no part of our business to condemn any decent dance, Irish dances are not to be put out of the place, that is their due, in any educational establishment under our care. They may not be the fashion in London or Paris. They should be the fashion in Ireland. Irish dances do not make degenerates.

We well know how so many of our people have of late been awaiting such a declaration as we now issue. Until otherwise arranged it is to be read at the principal Mass on the first Sunday of each Quarter of the Ecclesiastical Year. The priests will confer with responsible parishioners as regards the means by which it will be fully carried into effect. "And may the God of Peace Himself sanctify you in all things, that your whole spirit and soul and body may be blameless in the coming of our Lord Jesus Christ" (Thess. V. 23).

(Signed),

X Patrick O'Donnell,

Archbishop of Armagh,

Chairman.

X Robert Browne,

Bishop of Cloyne,

X Thomas O'Doherty,

Bishop of Galway

Secretaries.

6th October, 1925.

`;

const EvilsOfDancing = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Evils of Dancing
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						Evils of Dancing
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default EvilsOfDancing;
