import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const article = `
# The Dance at Marley
### _Patrick J. McCall (1861)_

Murtagh Murphy's barn was full to the door when the eve grew dull, For Phelim Moore his beautiful new pipes had brought to charm them; In the kitchen thronged the girls - cheeks of roses, teeth of pearls - Admiring bows and braids and curls, till Phelim's notes alarm them. Quick each maid her hat and shawl hung on dresser, bed, or wall, Smoothed down her hair and smiled on all as she the bawnoge entered, Where a shass of straw was laid on a ladder raised that made A seat for them as still they stayed while dancers by them cantered.

Murtagh and his vanithee had their chairs brought in to see The heels and toes go fast and free, and fun and love and laughter; In their sconces all alight shone the tallow candles bright - The flames kept jigging all the night, upleaping to each rafter! The pipes, with noisy drumming sound, the lovers' whispering sadly drowned, So the couples took their ground - their hearts already dancing! Merrily, with toe and heel, airily in jig and reel, Fast in and out they whirl and wheel, all capering and prancing.

"Off She Goes," "The Rocky Road," "The Tipsy House," and "Miss McLeod," "The Devil's Dream," and "Jig Polthogue," "The Wind that Shakes the Barley," "The First o'May," "The Garran Bwee," "Tatther Jack Welsh," "The River Lee," - As lapping breakers from the sea the myriad tunes at Marley! Reels of three and reels of four, hornpipes and jigs galore, With singles, doubles held the floor in turn, without a bar low; But when the fun and courting lulled, and the dancing somewhat dulled, The door unhinged, the boys down pulled for "Follow me up to Carlow."

Ned and Nelly, hand in hand, footed in a square so grand, Then back the jingling door they spanned, and swept swift as their glances, Nell, indignant-like, retired, chased by Ned until he tired, Her constancy so great admired, that he soon made advances. But young Nell would not be won, and a lover's chase came on - The maidens laughed to see the fun, till she surrendered fairly: Hands enclasped in rosy pride, tripping neatly side by side, They turned and bowed most dignified to all the folk of Marley!

Poorly pen of sage or scribe could such scenes of joy describe, Or due praises fair ascribe, where all were nearly equal! The love-making I've forgot in each cosy saustagh spot - Yet now I think I'd better not go tell, but wait the sequel. Everything must have an end, and the girshas home did wend, With guarding brother and a friend - this last was absent rarely! Late the Murphys by the hearth talked about the evening's mirth - Ne'er a dance upon the earth could match that one at Marley.
`;

const DanceAtMarley = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - The Dance at Marley
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						The Dance at Marley
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default DanceAtMarley;
